/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */

import { setInpostMachine, setPP48Machine } from "./js/components/api";
import { blockScreenScroll, allowScreenScroll } from "./js/utils/screens";
import { floatingHeader, floatingProductCard, floatingDecorHub } from "./js/components/floating-stuff";
import { initNewsletterForm } from "./js/components/newsletter";

import { initCartStuff } from "./js/components/cart-managment";
import { initFAQButtons, initExpandButtons, initExpandPromocode, mobileMenuExpanders } from "./js/components/content-expanders";
import IMask from "imask";

floatingHeader();
floatingProductCard();
floatingDecorHub();

initFAQButtons();
initExpandButtons();
initExpandPromocode();
mobileMenuExpanders();

initNewsletterForm();

const closeAllDialogs = () => {
  const $dialogs = document.querySelectorAll<HTMLDialogElement>("[data-dialog]");
  $dialogs.forEach(($dialog) => {
    $dialog.close();
  });
};

const $mobileMenuButton = document.querySelector<HTMLButtonElement>("[data-mobile-menu]");
if ($mobileMenuButton) {
  $mobileMenuButton.disabled = false;
  $mobileMenuButton.addEventListener("click", () => {
    const $mobileMenu = document.querySelector<HTMLDialogElement>("[data-mobile-menu-dialog]");
    if ($mobileMenu) {
      // Show modal
      $mobileMenu.showModal();
      $mobileMenu.classList.add("-translate-x-full");
      //   Block screen
      blockScreenScroll();
      //   On close remoce class once
      $mobileMenu.addEventListener(
        "close",
        () => {
          $mobileMenu.classList.remove("-translate-x-full");
        },
        { once: true }
      );
    }
  });
}

const $mobileLoginButton = document.querySelector<HTMLButtonElement>("[data-mobile-login]");
if ($mobileLoginButton) {
  $mobileLoginButton.disabled = false;
  $mobileLoginButton.addEventListener("click", () => {
    closeAllDialogs();
    const $dialog = document.querySelector<HTMLDialogElement>("[data-login-dialog]");
    if ($dialog) {
      // Show modal
      $dialog.showModal();
      $dialog.classList.add("-translate-x-full");
      //   Block screen
      blockScreenScroll();
      //   On close remoce class once
      $dialog.addEventListener(
        "close",
        () => {
          $dialog.classList.remove("-translate-x-full");
        },
        { once: true }
      );
    }
  });
}

const $dialogs = document.querySelectorAll<HTMLDialogElement>("[data-dialog]");
$dialogs.forEach(($dialog) => {
  $dialog.addEventListener("close", () => {
    allowScreenScroll();
  });
});

initCartStuff();

const expandCartDialogVoucher = ($event) => {
  const $element = $event.currentTarget.parentNode as HTMLDivElement;
  const $expand = $element.querySelector<HTMLDivElement>("[data-voucher-button-expand]");
  const $image = $element.querySelector<HTMLDivElement>("img");
  if ($expand) {
    const state = $expand.ariaHidden === "true";

    if (state === true) {
      $expand.classList.remove("max-h-0");
    }
    if (state === false) {
      $expand.classList.add("max-h-0");
    }

    $expand.ariaHidden = String(!state);

    if ($image) {
      if (state === true) {
        $image.classList.remove("rotate-180");
      }
      if (state === false) {
        $image.classList.add("rotate-180");
      }
    }
  }
};

const $voucherButtons = document.querySelectorAll<HTMLButtonElement>("[data-voucher-button]");
$voucherButtons.forEach(($button) => {
  $button.disabled = false;
  $button.addEventListener("click", expandCartDialogVoucher);
});

const handleCheckboxChange = (event: Event) => {
  if (event.currentTarget !== null) {
    const $el = event.currentTarget as HTMLInputElement;
    const state = $el.checked;
    const elementIndex = $el.getAttribute("data-expander-order");
    const $elementsToExpand = document.querySelectorAll<HTMLDivElement>(`[data-expand-order="${elementIndex}"]`);
    $elementsToExpand.forEach(($element) => {
      $element.setAttribute("data-section-show", state ? "true" : "false");
    });
  }
};

const $orderCheckboxes = document.querySelectorAll<HTMLInputElement>("[data-expander-order]");
$orderCheckboxes.forEach(($singleCheckbox) => {
  $singleCheckbox.addEventListener("input", handleCheckboxChange);
});

const handleDismissAlert = (event: Event) => {
  const $element = event.currentTarget as HTMLButtonElement;
  if ($element) {
    const dismissID = $element.getAttribute("data-dismiss");

    const $dismissElement = document.querySelector(`[data-dismissable='${dismissID}']`);
    if ($dismissElement) {
      $dismissElement.remove();
    }
  }
};
const $dismisableButtons = document.querySelectorAll<HTMLButtonElement>("[data-dismiss]");
$dismisableButtons.forEach(($dismisButton) => {
  $dismisButton.addEventListener("click", handleDismissAlert);
});

const setCheckboxes = (state: boolean): void => {
  const $checkboxElements = document.querySelectorAll<HTMLInputElement>("[data-check]");
  $checkboxElements.forEach(($singleCheckbox) => {
    $singleCheckbox.checked = state;
  });
};

const handleAllCheckboxesInCheckout = (event: Event): void => {
  const $checkboxElement = event.currentTarget as HTMLInputElement;

  if ($checkboxElement) {
    $checkboxElement.checked ? setCheckboxes(true) : setCheckboxes(false);
  }
};

const $checkAllCheckboxes = document.querySelector<HTMLInputElement>("[data-check-all-checkboxes]");
if ($checkAllCheckboxes) {
  $checkAllCheckboxes.addEventListener("change", handleAllCheckboxesInCheckout);
}

const $showMoreOpinionsButton = document.querySelector<HTMLButtonElement>("[data-show-more-opinions]");
if ($showMoreOpinionsButton) {
  $showMoreOpinionsButton.disabled = false;
  $showMoreOpinionsButton.addEventListener("click", (e: Event): void => {
    const $allOpinions = document.querySelectorAll<HTMLDivElement>("[data-single-opinion]");
    $allOpinions.forEach(($singleOpinionContainer) => {
      $singleOpinionContainer.classList.remove("hidden");
    });

    const $clickedButton = e.currentTarget as HTMLButtonElement;
    $clickedButton.disabled = true;
    $clickedButton.classList.add("hidden");
  });
}

const $paczkomatButton = document.querySelector("[data-id='3']");
if ($paczkomatButton) {
  $paczkomatButton.addEventListener("click", () => {
    const $dialog = document.querySelector<HTMLDialogElement>("[data-inpost]");
    if ($dialog) {
      // Show modal
      $dialog.showModal();
      //   Block screen
      blockScreenScroll();
      //   On close remoce class once
      $dialog.addEventListener("close", () => {}, { once: true });
    }
  });
}

window.afterPointSelected = function afterPointSelected(point) {
  setInpostMachine(point);
  const $dialog = document.querySelector<HTMLDialogElement>("[data-inpost]");
  if ($dialog) {
    $dialog.close();
  }
};

const setMasks = () => {
  let $input = document.getElementById("OrderForm_client_phone");
  if ($input) {
    IMask($input, {
      mask: "000000000",
    });
  }

  $input = document.getElementById("Client_client_phone");
  if ($input) {
    IMask($input, {
      mask: "000000000",
    });
  }
  $input = document.getElementById("OrderForm_client_send_phone");
  if ($input) {
    IMask($input, {
      mask: "000000000",
    });
  }

  $input = document.getElementById("OrderForm_client_post_code");
  if ($input) {
    IMask($input, {
      mask: "00-000",
    });
  }

  $input = document.getElementById("Client_client_send_post_code");
  if ($input) {
    IMask($input, {
      mask: "00-000",
    });
  }

  $input = document.getElementById("OrderForm_client_send_post_code");
  if ($input) {
    IMask($input, {
      mask: "00-000",
    });
  }
  $input = document.getElementById("Client_client_post_code");
  if ($input) {
    IMask($input, {
      mask: "00-000",
    });
  }

  $input = document.getElementById("OrderForm_client_nip");
  if ($input) {
    IMask($input, {
      mask: "0000000000",
    });
  }
};
setMasks();

const handleShowMore = (event) => {
  const index = event.currentTarget.getAttribute("data-show-more-button");
  const $el = document.querySelectorAll(`[data-show-more-container='${index}']`);
  $el.forEach((element) => {
    element.classList.remove("max-h-0");
  });
  event.currentTarget.remove();
};
const $ex = document.querySelectorAll("[data-show-more-button]");
$ex.forEach((element) => {
  element.addEventListener("click", handleShowMore);
});

// const favicon = () => {
//   var favicon_images = [
//       "https://t3st.justmeal.com/favicon/favicon-frame-1.gif",
//       "https://t3st.justmeal.com/favicon/favicon-frame-2.gif",
//       "https://t3st.justmeal.com/favicon/favicon-frame-3.gif",
//     ],
//     image_counter = 0; // To keep track of the current image

//   setInterval(function () {
//     // remove current favicon
//     const icon_1 = document.querySelector("link[rel='icon']");
//     if (icon_1) {
//       icon_1.remove();
//     }
//     const icon_2 = document.querySelector("link[rel='shortcut icon']");
//     if (icon_2) {
//       icon_2.remove();
//     }

//     // add new favicon image
//     document
//       .querySelector("head")
//       .insertAdjacentHTML("beforeend", '<link rel="icon" href="' + favicon_images[image_counter] + '" type="image/gif">');

//     // If last image then goto first image
//     // Else go to next image
//     if (image_counter == favicon_images.length - 1) image_counter = 0;
//     else image_counter++;
//   }, 500);
// };
// favicon();

const handleShowSubs = () => {
  closeAllDialogs();
  const $dialog = document.querySelector<HTMLDialogElement>("[data-subs]");
  if ($dialog) {
    $dialog.showModal();
    blockScreenScroll();
  }
};

const showSubs = () => {
  const $subButton = document.querySelector<HTMLButtonElement>("[data-show-subs]");
  if ($subButton) {
    $subButton.disabled = false;
    $subButton.addEventListener("click", handleShowSubs);
  }
};
showSubs();

const getDeliveryAddressForPP48 = () => {
  const r_street = document.querySelector("input#OrderForm_client_street").value;
  const r_building = document.querySelector("input#OrderForm_client_building").value;
  const r_city = document.querySelector("input#OrderForm_client_city").value;
  const r_country = "Polska";

  if (!r_street) {
    return false;
  }
  if (!r_building) {
    return false;
  }
  if (!r_city) {
    return false;
  }
  if (!r_country) {
    return false;
  }

  return {
    street: r_street,
    building: r_building,
    city: r_city,
    country: r_country,
  };
};

const getAlternativeDeliveryAddressForPP48 = () => {
  const r_street = document.querySelector("input#OrderForm_client_send_street").value;
  const r_building = document.querySelector("input#OrderForm_client_send_building").value;
  const r_city = document.querySelector("input#OrderForm_client_send_city").value;
  const r_country = "Polska";

  if (!r_street) {
    return false;
  }
  if (!r_building) {
    return false;
  }
  if (!r_city) {
    return false;
  }
  if (!r_country) {
    return false;
  }

  return {
    street: r_street,
    building: r_building,
    city: r_city,
    country: r_country,
  };
};

const spawnPP48PointWidget = ($element) => {
  if ($element) {
    $element.addEventListener("click", () => {
      let $de = {
        street: "",
        building: "",
        city: "",
        country: "Polska",
      };

      if (getDeliveryAddressForPP48() !== false) {
        $de = getDeliveryAddressForPP48();
      }

      if (getAlternativeDeliveryAddressForPP48() !== false) {
        $de = getAlternativeDeliveryAddressForPP48();
      }

      const address = !$de.street ? "" : [[$de.street, $de.building].join(" "), $de.city, $de.country].join(", ");

      PPWidgetApp.toggleMap(
        function (point) {
          setPP48Machine(point);
        },
        false,
        address
      );
    });
  }
};

spawnPP48PointWidget(document.querySelector("[data-id='12']"));
