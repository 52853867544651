/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */
import wretch from "wretch";

declare global {
  const lang: string;
}

type CartSatistics = {
  cartValue: number;
  cartItems: number;
  cartWeight: number;
};

const API_CART_STATISTICS = "/cart/cartstats";
const API_CART_REMOVE_PROMOCODE = "/cart/removePromocode";
const API_CART_INPOST_MACHINE = "/paczkomaty/select/machine/";
const API_CART_PP48_MACHINE = "/cart/selectpp48point/point/";

export const updateCartStatistics = async (): Promise<void> => {
  const updateCartCounters = (cartCounter: number): void => {
    const $cartCountersElements = document.querySelectorAll<HTMLElement>("[data-cart-counter]");
    $cartCountersElements.forEach(($element) => {
      $element.innerHTML = cartCounter > 0 ? String(cartCounter) : "";
    });
  };

  const updateCartCountersWithWords = (cartCounter: number): void => {
    const $cartCountersElements = document.querySelectorAll<HTMLElement>("[data-cart-items-plular]");
    $cartCountersElements.forEach(($element) => {
      $element.innerHTML = String(cartCounter);
    });
  };

  const updateCartValue = (cartValue: number): void => {
    const $cartValuesElements = document.querySelectorAll<HTMLElement>("[data-cart-value]");
    $cartValuesElements.forEach(($element) => {
      $element.innerHTML = String(cartValue);
    });
  };

  wretch()
    .get(API_CART_STATISTICS)
    .json<CartSatistics>()
    .then((data) => {
      updateCartCounters(data.cartItems);
      updateCartCountersWithWords(data.cartItems);
      updateCartValue(data.cartValue);
    })
    .catch((_) => {
      console.info(`[API] (${API_CART_STATISTICS}) fetch error.`);
    });
};

export const handleRemoveOfPromocode = () => {
  wretch()
    .delete(API_CART_REMOVE_PROMOCODE)
    .res()
    .then(() => {
      location.reload();
    })
    .catch((_) => {
      console.info(`[API] (${API_CART_REMOVE_PROMOCODE}) error.`);
    });
};

export const setInpostMachine = (point): void => {
  const encodedPoint = encodeURI(point.name);
  wretch()
    .get(`${API_CART_INPOST_MACHINE}${encodedPoint}`)
    .res()
    .then(() => {
      console.info(`[API] (${API_CART_INPOST_MACHINE}) communicated. - ${point.name}`);
    })
    .catch((_) => {
      console.info(`[API] (${API_CART_INPOST_MACHINE}) error.`);
    });
};

export const setPP48Machine = (point): void => {
  const encodedPoint = encodeURI(point.pni);
  wretch()
    .get(`${API_CART_PP48_MACHINE}${encodedPoint}`)
    .res()
    .then(() => {
      console.info(`[API] (${API_CART_PP48_MACHINE}) communicated. - ${point.pni}`);
    })
    .catch((_) => {
      console.info(`[API] (${API_CART_PP48_MACHINE}) error.`);
    });
};
